function show (msg, duration) {
  duration = isNaN(duration) ? 1500 : duration
  const m = document.createElement('div')
  const p = document.createElement('p')
  p.innerHTML = msg
  p.style.cssText = 'color: white; height: 1.2rem; font-size: 1rem;padding:0.6rem 1rem;padding-top: .6rem;min-width:3rem; background: rgba(0, 0, 0, 0.7);line-height:1rem; border-radius:2rem; text-align: center; align-item:center;'
  m.style.cssText =
    'width:100%; font-size: 0.2rem; display:flex; justify-content: center;align-items: center;padding:0 0rem; position:fixed; top:30%; z-index:999999;'
  m.appendChild(p)
  document.getElementById('app').appendChild(m)
  setTimeout(function () {
    const d = 0.5
    m.style.webkitTransition =
      '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in'
    m.style.opacity = '0'
    setTimeout(function () {
      document.getElementById('app').removeChild(m)
    }, d * 1000)
  }, duration)
}

function show_yellow (msg, duration) {
  duration = isNaN(duration) ? 3000 : duration
  const m = document.createElement('div')
  const p = document.createElement('p')
  p.innerHTML = msg
  p.style.cssText = 'color: #FFF713;; height: 2rem; font-size: 1rem;padding:0.2rem 2rem;min-width:3rem; background: rgba(0, 0, 0, 0.7);line-height:1.6rem; border-radius:2rem;  text-align: center;'
  m.style.cssText =
      'width:100%;  font-size: 0.2rem; display:flex; justify-content: center;align-items: center;padding:0 2rem; position:fixed; top:50%; z-index:999999;'
  m.appendChild(p)
  document.getElementById('app').appendChild(m)
  setTimeout(function () {
    const d = 0.5
    m.style.webkitTransition =
        '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in'
    m.style.opacity = '0'
    setTimeout(function () {
      document.getElementById('app').removeChild(m)
    }, d * 1000)
  }, duration)
}

export default { show, show_yellow }
